<template>
  <b-container class="py-bw-5 text-left" id="agbContainer">
    <b-row>
      <b-col cols="12">
        <h2 class="mb-4 h2-title">
          AGB
        </h2>
        <h4 class="mb-4 h4-title">
          Allgemeine Geschäftsbedingungen
        </h4>
        <p class="p-bw">
          1. Geltungsbereich und Anbieter
          <br><br>
          1.1 Diese Allgemeinen Geschäftsbedingungen gelten für die Nutzung des Onlineshops der Barliner Workout, Inh.
          Denis Vitaliev, Heinrich-Heine-Str. 5, 10179 Berlin (nachfolgend „Barliner Workout“ oder „Wir“ genannt), sowie
          für alle Bestellungen, die Kunden über den Onlineshop von Barliner Workout tätigen.
          <br><br>
          1.2 Mit der Registrierung beim bzw. spätestens mit der Nutzung des Barliner Workout-Onlineshops akzeptierst Du
          die Geltung und Anwendbarkeit dieser AGB.
          <br><br>
          2. Registrierung
          <br><br>
          2.1 Du hast die Möglichkeit Dich bei Barliner Workout als Kunde zu registrieren. Die Registrierung als Kunde ist
          kostenfrei.
          <br><br>
          2.2 Die Registrierung im Barliner Workout-Onlineshop ist nur volljährigen und unbeschränkt geschäftsfähigen
          natürlichen und/oder juristischen Personen gestattet.
          <br><br>
          2.3 Jegliche für die Registrierung erforderlichen Angaben sind vollständig und richtig zu machen und stets
          aktuell zu halten.
          <br><br>
          2.4 Barliner Workout ist berechtigt die Nutzung des Onlineshops bzw. einzelner Funktionen, von einem geeigneten
          Nachweis über Deine Identität und/oder Deine Geschäftsfähigkeit abhängig zu machen.
          <br><br>
          2.5 Mit der Registrierung bei Barliner Workout legst Du ein Passwort an, welches in Verbindung mit Deiner
          E-Mailadresse die leichtere Nutzung des Onlineshops ermöglicht. Ohne eine Registrierung müssen die
          vertragsrelevanten Daten (Name, Anschrift, Geburtsdatum, E-Mailkontakt) bei jeder Bestellung erneut eingegeben
          werden.
          <br><br>
          2.6 Das verwendete Passwort ist von Dir geheim zu halten. Die Geheimhaltung liegt vollständig und allein in
          Deiner Verantwortung. Das Barliner Workout-Kundenkonto darf nur von Dir selbst genutzt werden. Jegliche
          unbefugte Nutzung des eigenen Kundenkontos, sowie jeder dahingehende Verdacht ist Barliner Workout unverzüglich
          mitzuteilen.
          <br><br>
          2.7 Wir sind berechtigt, die von Dir im Rahmen der Registrierung angegebenen Daten nach Maßgabe der Bestimmungen
          zum Datenschutz zu speichern und zu verarbeiten.
          <br><br>
          3. Vertragsschluss und Vertragstextspeicherung
          <br><br>
          3.1 Produkte/Waren können von Dir unverbindlich durch Anklicken des Buttons [in den Warenkorb] vorgemerkt
          werden. Der Warenkorb kann jederzeit durch Anklicken des Buttons [Warenkorb] angesehen und Produkte/Waren ggf.
          durch Anklicken des Buttons [ Minuszeichen, neben der Mengenangabe] wieder aus dem Warenkorb entfernt werden.
          Wenn Du die Produkte/Waren im Warenkorb kaufen willst, musst Du zunächst den Button [zur Kasse] anklicken.
          <br><br>
          3.2 Jegliche Produkt-/Warendarstellungen im Barliner Workout-Onlineshop dienen zur Abgabe eines Kaufangebotes.
          Auf der Bestellseite gibst Du mit Anklicken des Buttons [jetzt kostenpflichtig bestellen] ein verbindliches
          Kaufangebot ab.
          <br><br>
          3.3 Wir können Deine Bestellung durch Versand einer separaten Auftragsbestätigung per E-Mail oder durch
          Auslieferung der Ware innerhalb von fünf Tagen annehmen. Die Bestätigung des Zugangs der Bestellung erfolgt
          durch eine automatisierte E-Mail unmittelbar nach dem Absenden der Bestellung und stellt noch keine
          Vertragsannahme dar – kann aber mit einer solchen verbunden sein.
          <br><br>
          3.4 Sollte unsere Auftragsbestätigung Schreib- oder Druckfehler enthalten oder sollten unserer Preisfestlegung
          technisch bedingte Übermittlungsfehler zu Grunde liegen, so sind wir zur Anfechtung berechtigt. Bereits erfolgte
          Zahlungen werden Dir unverzüglich erstattet.
          <br><br>
          3.5 Der Vertragstext wird nicht von Barliner Workout gespeichert. Die Bestelldaten werden Dir per E-Mail
          zugesendet.
          <br><br>
          3.6 Der Vertragsschluss erfolgt unter dem Vorbehalt der richtigen und rechtzeitigen Selbstbelieferung durch
          unsere Zulieferer. Dies gilt nur für den Fall, dass die Nichtlieferung nicht von uns zu vertreten ist,
          insbesondere bei Abschluss eines kongruenten Deckungsgeschäftes mit unserem Zulieferer. Wir übernehmen
          ausdrücklich kein Beschaffungsrisiko, wenn wir einen Bezugsvertrag über die geschuldete Leistung mit unserem
          Lieferanten geschlossen haben. Das gilt nicht, wenn Du Verbraucher im Sinne des § 13 BGB bist.
          <br><br>
          4. Preise
          <br><br>
          Die auf den Produktseiten genannten Preise enthalten die gesetzliche Mehrwertsteuer und sonstige
          Preisbestandteile.
          <br><br>
          5. Versandkosten
          <br><br>
          5.1 Für die Lieferung bestellter Waren fallen die bei der Bestellung aufgeführten Versandkosten, ggf. zusätzlich
          zum Kaufpreis, an.
          <br><br>
          5.2 Bei der Zustellung einer Sendung aus dem Ausland müssen Einwohner der Schweiz Gebühren zahlen
          (Mehrwertsteuer, Zollabgaben). Jede Sendung aus dem Ausland ist für Einwohner der Schweiz grundsätzlich zoll-
          und mehrwertsteuerpflichtig. Über Zölle bei der Lieferung in Nicht-EU-Länder kannst Du Dich z.B. unter folgenden
          Link informieren:
          http://ec.europa.eu/taxation_customs/customs/customs_duties/tariff_aspects/customs_tariff/index_de.htm Über
          Einfuhrumsatzsteuern bei der Lieferung in Nicht-EU-Länder können Sie sich z.B. unter dem folgenden Link
          informieren: http://auskunft.ezt-online.de/ezto/Welcome.do Informationen speziell für die Schweiz finden Sie
          z.B. unter: http://xtares.admin.ch/tares/.
          <br><br>
          5.3 Bei der Selbstabholung der Ware durch den Kunden fallen keine Versandkosten an.
          <br><br>
          6. Lieferbedingungen und Selbstbelieferungsvorbehalt
          <br><br>
          6.1 Die Lieferung erfolgt innerhalb Deutschlands mit DHL. Bei internationalen Sendungen wird das Paket an das
          zuständige Versandunternehmen des jeweiligen Landes übergeben.
          <br><br>
          6.2 Die Lieferzeit beträgt, sofern beim Angebot nicht anders angegeben, 5-7 Tage ab Vertragsschluss (vgl. Ziff.
          3.2).
          <br><br>
          6.3 Sollten nicht alle bestellten Produkte vorrätig sein, ist Barliner Workout zu Teillieferungen auf eigene
          Kosten berechtigt.
          <br><br>
          6.4 Sollte die Zustellung der Ware trotz dreimaligen Auslieferungsversuchs scheitern, kann Barliner Workout vom
          Vertrag zurücktreten. Ggf. geleistete Zahlungen werden Dir unverzüglich erstattet.
          <br><br>
          6.5 Wenn das bestellte Produkt nicht verfügbar ist, weil wir mit diesem Produkt von einem Lieferanten ohne
          eigenes Verschulden nicht beliefert wurden, können wir vom Vertrag zurücktreten. In diesem Fall wirst Du von uns
          unverzüglich informiert und Dir wird ggf. die Lieferung eines vergleichbaren Produktes vorschlagen. Wenn kein
          vergleichbares Produkt verfügbar ist oder keine Lieferung eines vergleichbaren Produktes gewünscht ist, werden
          wir Dir ggf. bereits erbrachte Zahlungen unverzüglich erstatten.
          <br><br>
          7. Zahlungsbedingungen
          <br><br>
          7.1 Die Zahlung erfolgt mittels den im Onlineshop bzw. im Rahmen des Bestellprozesses angegebenen
          Zahlungsmethoden.
          <br><br>
          7.2 Bei Auswahl der Zahlungsart Vorkasse nennen wir Dir die relevante Bankverbindung in der Kaufbestätigung. Der
          Rechnungsbetrag ist binnen 14 Tagen auf das benannte Konto zu überweisen. Bei Zahlung per Kreditkarte erfolgt
          die Belastung Deines Kontos mit dem Abschluss der Bestellung.
          <br><br>
          7.3 Wir senden Rechnungen grundsätzlich zusammen mit der Auftragsbestätigung per E-Mail an die von Dir bei der
          Bestellung bzw. Registrierung angegebene E-Mailadresse. Solltest Du einen Rechnungsausdruck wünschen, informiere
          uns bitte rechtzeitig unter Angabe Deiner Bestellnummer darüber. Wir lassen Dir die Papierrechnung dann zusammen
          mit den bestellten Waren zukommen.
          <br><br>
          In Zusammenarbeit mit Klarna Bank AB (publ), Sveavägen 46, 111 34 Stockholm, Schweden, bieten wir die folgenden
          Zahlungsoptionen an. Die Zahlung erfolgt jeweils an Klarna:
          <br><br>
        <ul>
          <li>
            Rechnung: Die Zahlungsfrist beträgt [14] Tage ab Versand der Ware/ des Tickets/ oder, bei sonstigen
            Dienstleistungen, der Zurverfügungstellung der Dienstleistung. Die vollständigen Rechnungsbedingungen für die
            Länder in denen diese Zahlart verfügbar ist finden Sie hier: Deutschland, Österreich.
            <br><br>
          </li>
          <li>
            Ratenkauf: Mit dem Finanzierungsservice von Klarna können Sie Ihren Einkauf in festen oder flexiblen
            monatlichen Raten zu den in der Kasse angegebenen Bedingungen bezahlen. Die Ratenzahlung ist jeweils zum Ende
            des Monats nach Übersendung einer Monatsrechnung durch Klarna fällig. Weitere Informationen zum Ratenkauf
            einschließlich der Allgemeinen Geschäftsbedingungen und der europäischen Standardinformationen für
            Verbraucherkredite für die Länder in denen diese Zahlart verfügbar ist finden Sie hier (nur in den angegebenen
            Ländern verfügbar): Deutschland, Österreich.
            <br><br>
          </li>
          <li>
            Sofort: Verfügbar in Deutschland und Österreich. Die Belastung Ihres Kontos erfolgt unmittelbar nach Abgabe
            der Bestellung.
            <br><br>
          </li>
          <li>
            Lastschrift: Verfügbar in Deutschland und Österreich. Die Abbuchung erfolgt nach Versand der Ware. Der
            Zeitpunkt wird Ihnen per E-Mail mitgeteilt.
            <br><br>
          </li>
        </ul>
        Die Nutzung der Zahlungsarten Rechnung, Ratenkauf und Lastschrift setzt eine positive Bonitätsprüfung voraus.
        Insofern leiten wir Ihre Daten im Rahmen der Kaufanbahnung und Abwicklung des Kaufvertrages an Klarna zum Zwecke
        der Adress- und Bonitätsprüfung weiter. Bitte haben Sie Verständnis dafür, dass wir Ihnen nur diejenigen Zahlarten
        anbieten können, die aufgrund der Ergebnisse der Bonitätsprüfung zulässig sind. Weitere Informationen und Klarnas
        Nutzungsbedingungen finden Sie hier. Allgemeine Informationen zu Klarna erhalten Sie hier. Ihre Personenangaben
        werden von Klarna in Übereinstimmung mit den geltenden Datenschutzbestimmungen und entsprechend den Angaben in
        Klarnas Datenschutzbestimmungen behandelt.
        <br><br>
        8. Eigentumsvorbehalt
        <br><br>
        8.1 Die Ware bleibt bis zur vollständigen Zahlung Eigentum von Barliner Workout. Vor Übergang des Eigentums ist
        eine Verpfändung, Sicherungsübereignung, Verarbeitung oder Umgestaltung ohne unsere Zustimmung nicht gestattet.
        <br><br>
        8.2 Wenn Sie Unternehmer (§ 14 BGB) sind, so gilt folgendes zusätzlich: Evope behält sich aus der
        Geschäftsbeziehung mit dem Kunden das Eigentum an der gelieferten Ware in einfacher, verlängerter und erweiterter
        Form bis zur Erfüllung aller Forderungen (einschließlich sämtlicher Saldoforderungen aus Kontokorrent ), die
        Barliner Workout aus jedem Rechtsgrund gegen den Kunden jetzt oder künftig zustehen, bzw. bis zur restlosen
        Erfüllung aller Ansprüche aus dem jeweiligen Vertrag, vor. Daher werden Barliner Workout die folgenden
        Sicherheiten gewährt, die auf Verlangen nach Wahl von Barliner Workout freigegeben werden, wenn und soweit ihr
        Wert abzüglich der zur Sicherung aufzuwendenden Kosten die Forderungen nachhaltig um mehr als 20% übersteigt. Der
        Kunde darf die Vorbehaltsware im ordentlichen Geschäftsgang weiter veräußern. Zur Sicherungsübereignung und
        Verpfändung der Vorbehaltsware ist er nicht berechtigt. Die Weiterveräußerung der Ware ist nur unter Weitergabe
        des Eigentumsvorbehaltes zulässig. Für den Fall, dass der Kunde beim Weiterverkauf den Eigentumsvorbehalt nicht
        weiter gibt, tritt er seine Forderung gegen den Erwerber hiermit an Barliner Workout ab. Das gilt auch für die
        Saldoforderung aus einem Kontokorrent, wenn der Kunde mit seinem Abnehmer ein solches vereinbart hat. Evope nimmt
        die Abtretung hiermit an. Barliner Workout kann vom Kunden verlangen, dass er die abgetretenen Forderungen und
        deren Schuldner bekannt gibt. Barliner Workout ist sodann berechtigt, die Abtretung nach wahlweise offen zu legen.
        Bei Zahlungsverzug kann Barliner Workout die Herausgabe des Gegenstandes, für den der Eigentumsvorbehalt besteht,
        binnen einer angemessenen Frist verlangen und über den Gegenstand anderweitig verfügen und nach Zahlung durch den
        Kunden diesen in angemessener Frist mit einem anderen Gegenstand neu beliefern. Der Kunde ist zur sachgemäßen
        Lagerung der Vorbehaltsware und zu deren ordnungsgemäßer Versicherung verpflichtet. Gerät der Kunde in
        Zahlungsverzug oder greifen Dritte auf die Vorbehaltsware zu oder gerät der Kunde in Vermögensverfall, ist
        Barliner Workout berechtigt, zur Geltendmachung des Eigentumsvorbehaltes die Geschäftsräume des Kunden zu betreten
        und die Vorbehaltsware an sich zu nehmen. Der Kunde erlaubt den Mitarbeitern von Barliner Workout hiermit,
        jederzeit seine Geschäftsräume zur Sicherstellung der Ware zu betreten. Der Gerichtsvollzieher wird ermächtigt,
        die Ware nach der Aufhebung der Pfändung an Barliner Workout auszuhändigen.
        <br><br>
        9. Widerrufsrecht
        <br><br>
        9.1 Verbrauchern (§13 BGB) steht ein gesetzliches Widerrufsrecht entsprechend der nachfolgenden Bedingungen zu.
        Eine PDF Vorlage finden Sie hier.
        </p>
        <hr>

        <h4 class="mb-4 h4-title">
          Widerrufsbelehrung:
        </h4>
        <p class="p-bw">
          Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die
          Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der
          Beförderer ist, die letzte Ware in Besitz genommen haben bzw. hat. Um Ihr Widerrufsrecht auszuüben, müssen Sie
          uns,
          <br><br>
          Barliner Workout<br>
          Inh. Denis Vitaliev<br>
          Heinrich-Heine-Str. 5<br>
          10179 Berlin<br>
          E-Mail: denis.vitaliev@barlinerworkout.com
          <br><br>
          mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren
          Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular
          verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die
          Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
        </p>
        <h4 class="my-4 h4-title">
          Folgen des Widerrufs
        </h4>
        <p class="p-bw">
          Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
          einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine
          andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich
          und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses
          Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
          ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart;
          in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern,
          bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren
          zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
          <br><br>
          Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns
          über den Widerruf dieses Vertrags unterrichten, an
          <br><br>
          Barliner Workout<br>
          Inh. Denis Vitaliev<br>
          Heinrich-Heine-Str. 5<br>
          10179 Berlin<br>
          E-Mail: denis.vitaliev@barlinerworkout.com
          <br><br>
          zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn
          Tagen absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren. Sie müssen für einen etwaigen
          Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit,
          Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.
        </p>
        <h4 class="my-4 h4-title">
          Ende der Widerrufsbelehrung
        </h4>
        <hr>
        <p class="p-bw">
          9.2 Das Widerrufsrecht besteht nicht bei Lieferung von Waren,
          <br><br>
        <ul>
          <li>
            die nicht vorgefertigt sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den
            Verbraucher maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse des Verbrauchers zugeschnitten
            sind, zur Lieferung von Waren, die schnell verderben können oder deren Verfallsdatum schnell überschritten
            würde,
          </li>
          <li>
            die aus Gründen des Gesundheitsschutzes oder der Hygiene nicht zur Rückgabe geeignet sind, wenn ihre
            Versiegelung nach der Lieferung entfernt wurde,
          </li>
          <li>
            wenn diese nach der Lieferung auf Grund ihrer Beschaffenheit untrennbar mit anderen Gütern vermischt wurden,
          </li>
          <li>
            sowie auch nicht bei der Lieferung von Ton- oder Videoaufnahmen oder Computersoftware in einer versiegelten
            Packung, wenn die Versiegelung nach der Lieferung entfernt wurde,
          </li>
          <li>
            zur Lieferung alkoholischer Getränke, deren Preis bei Vertragsschluss vereinbart wurde, die aber frühestens 30
            Tage nach Vertragsschluss geliefert werden können und deren aktueller Wert von Schwankungen auf dem Markt
            abhängt, auf die der Unternehmer keinen Einfluss hat.
          </li>
        </ul>
        9.3 Bitte vermeide Beschädigungen und Verunreinigungen der Ware. Sende die Ware bitte möglichst in
        Originalverpackung mit sämtlichem Zubehör und mit allen Verpackungsbestandteilen an uns zurück. Bitte verwende
        ggf. eine schützende Umverpackung. Wenn Du die Originalverpackung nicht mehr besitzt, sorge bitte mit einer
        geeigneten Verpackung für einen ausreichenden Schutz vor Transportschäden, um Schadensersatzansprüche wegen
        Beschädigungen infolge mangelhafter Verpackung zu vermeiden.
        <br><br>
        9.4 Bitte beachten, dass die in Ziff. 9.3 genannten Modalitäten nicht Voraussetzungen für die wirksame Ausübung
        des Widerrufsrechts sind.
        <br><br>
        9.5 Widerrufsformular
        <br><br>
        Muster-Widerrufsformular
        <br><br>
        (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)
        Eine PDF Vorlage finden Sie hier
        <br><br>
        Barliner Workout, Inh. Denis Vitaliev, Heinrich-Heine-Str. 5, 10179 Berlin, E-Mail:
        denis.vitaliev@barlinerworkout.com
        <br><br>
        &mdash; Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über
        <br>
        &mdash; den Kauf der folgenden Waren (*)/ die Erbringung der folgenden Dienstleistung (*)
        <br>
        &mdash; Bestellt am (*)/erhalten am (*)
        <br>
        &mdash; Name des/der Verbraucher(s)
        <br>
        &mdash; Anschrift des/der Verbraucher(s)
        <br>
        &mdash; Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
        <br>
        &mdash; Datum
        <br><br>
        </p>
        <hr>
        <p class="p-bw">
          (*) Unzutreffendes streichen
          <br><br>
          10. Transportschäden
          <br><br>
          10.1 Werden Waren mit offensichtlichen Transportschäden angeliefert, so reklamiere solche Fehler bitte sofort
          bei dem Zusteller, und nimm bitte schnellstmöglich Kontakt zu uns auf.
          <br><br>
          10.2 Sofern Du Verbraucher im Sinne des §13 BGB bist, hat die Versäumung einer Reklamation oder Kontaktaufnahme
          für Deine gesetzlichen Gewährleistungsrechte keinerlei Konsequenzen. Du hilfst uns aber, damit wir unsere
          eigenen Ansprüche gegenüber dem Frachtführer bzw. der Transportversicherung geltend machen können und so unseren
          Service für Dich weiter verbessern zu können.
          <br><br>
          10.3 Sofern Sie kein Verbraucher im Sinne des § 13 BGB sind, setzen Mängelansprüche (Gewährleistungsansprüche)
          Ihrerseits voraus, dass Sie die Ware gem. § 377 HGB unverzüglich nach deren Erhalt und etwaige entdeckte Mängel
          unverzüglich nach der Untersuchung – versteckte Mängel unverzüglich nach deren Entdeckung – unter spezifizierter
          Angabe des Mangels schriftlich gegenüber Evope Nutrition gerügt haben. Die Ware gilt hinsichtlich
          offensichtlicher Mängel oder anderer Mängel, die bei einer unverzüglichen, sorgfältigen Untersuchung erkennbar
          gewesen wären, als vom Kunden genehmigt, sofern dieser Evope Nutrition nicht gemäß dieser Ziff. 10.3 über diesen
          Mangel informiert.
          <br><br>
          11. Gewährleistung
          <br><br>
          Die Gewährleistung erfolgt nach den gesetzlichen Bestimmungen, sofern in diesen Allgemeinen Geschäftsbedingungen
          nichts anderes geregelt ist.
          <br><br>
          12. Kundendaten
          <br><br>
          12.1 Wir sind berechtigt, die von Dir an uns übermittelten Daten – insbesondere die im Rahmen von Bestellungen
          angegebenen Kundendaten – zu den vertragsgemäßen Zwecken zu speichern und zu nutzen.
          <br><br>
          12.2 Die Speicherung und die Nutzung erfolgt nach Maßgabe der unserer Datenschutzerklärung sowie der geltenden
          Gesetze (insb. des Bundesdatenschutzgesetzes).
          <br><br>
          13. Haftung
          <br><br>
          13.1 Wir haften nur für Schäden, die aus einem vorsätzlichen oder grob fahrlässigen Verhalten unsererseits,
          unseren Mitarbeitern, gesetzlichen Vertretern oder Erfüllungsgehilfen herrühren. Dies gilt nicht für Schäden,
          die aus einer schuldhaften Verletzung des Lebens, des Körpers, der Gesundheit oder von wesentlichen
          Vertragspflichten (Kardinalpflichten) herrühren. Wesentliche Vertragspflichten sind solche, deren Erfüllung die
          ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Erfüllung der Kunde vertrauen
          darf.
          <br><br>
          13.2 Gegenüber Unternehmern ist die Haftung wegen der Verletzung wesentlicher Vertragspflichten ferner der Höhe
          nach auf den vertragstypischen, vorhersehbaren Schaden begrenzt, sofern dieser einfach fahrlässig verursacht
          wurde und er nicht zugleich aus einer Verletzung des Lebens, des Körpers oder der Gesundheit herrührt.
          <br><br>
          13.3 Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt.
          <br><br>
          14. Änderungen dieser Allgemeinen Geschäftsbedingungen
          <br><br>
          14.1 Wir können diese Allgemeinen Geschäftsbedingungen jederzeit mit Wirkung für die Zukunft ohne Angabe von
          Gründen ändern anpassen bzw. aktualisieren, sofern Dir dies unter Berücksichtigung der beiderseitigen Interessen
          zumutbar ist. Unzumutbar ist eine Änderung dann, wenn sie wesentliche Vertragsbestandteile, insbesondere die
          geschuldeten Hauptleistungen, welche Gegenstand eines Änderungsvertrags sein müssten, zum Gegenstand hat.
          <br><br>
          14.2 Wir werden Dich rechtzeitig – spätestens zwei Wochen vor Inkrafttreten der geänderten Allgemeinen
          Geschäftsbedingungen – per E-Mail an eine von Dir angegebene E-Mailadresse über anstehende Änderungen
          informieren sowie auf die Möglichkeit und Frist des Widerspruchs und auf die Folgen des Unterlassens des
          Widerspruchs hinweisen.
          <br><br>
          14.3 Sofern Du der Änderung nicht innerhalb von zwei Wochen nach Inkrafttreten der geänderten Allgemeinen
          Geschäftsbedingungen widerspricht, gelten diese als angenommen. Die Frist beginnt mit dem Tag des
          Inkrafttretens, welcher zuvor per E-Mail angekündigt wurde, oder andernfalls mit dem Tag, an dem Du von der
          Änderung erstmals Kenntnis erlangst.
          <br><br>
          14.4 Im Fall des fristgerechten Widerspruchs sind wir unter Berücksichtigung der Deiner berechtigten Interessen
          berechtigt, das Nutzungsverhältnis mit Dir mit Wirkung für den Zeitpunkt aufzulösen, zu dem die geänderten
          Allgemeinen Geschäftsbedingungen in Kraft treten.
          <br><br>
          15. Hinweis gem. Art. 14 ODR-Verordnung
          <br><br>
          15.1 Sofern Du Verbraucher im Sinne des § 13 BGB bist, wird darauf hingewiesen, dass die Europäische Kommission
          seit dem
          <br><br>
          15.02.2016 hier http://ec.europa.eu/consumers/odr/ eine
          <br><br>
          Plattform zur Online-Streitbeilegung bereitstellt. Die E-Mailadresse des Anbieters lautet:
          mail@verbraucher-schlichter.de.
          <br><br>
          15.2 Das Online-Schlichtungsverfahren ist nicht zwingende Voraussetzung für das Anrufen zuständiger ordentlicher
          Gerichte, sondern stellt eine alternative Möglichkeit dar, Differenzen, die im Rahmen eines
          Vertragsverhältnisses auftreten können, zu beseitigen.
          <br><br>
          15.3 Sonstige nationale Vorschriften zur Durchführung von Schlichtungsverfahren bleiben von den vorstehenden
          Regelungen in Ziffer 15.1 und 15.2 unberührt.
          <br><br>
          16. Schlussbestimmungen
          <br><br>
          16.1 Diese Allgemeinen Geschäftsbedingungen regeln das Vertragsverhältnis umfassend und abschließend. Änderungen
          und Ergänzungen bedürfen zu Ihrer Wirksamkeit der Schriftform. Dies gilt auch für die Aufhebung dieser
          Schriftformklausel.
          <br><br>
          16.2 Sollte eine Bestimmung dieser Allgemeinen Geschäftsbedingungen unwirksam sein, so bleiben die Allgemeinen
          Geschäftsbedingungen im Übrigen wirksam. Anstelle der unwirksamen Bestimmung gelten die einschlägigen
          gesetzlichen Vorschriften.
          <br><br>
          16.3 Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss der Kollisionsregeln des EGBGB und der
          Regelungen des UN-Kaufrechts (CISG). Zwingende nationale Schutzrechte (z.B. zwingende Verbraucherschutzrechte
          etc.) bleiben von der vorstehenden Rechtswahlklausel unberührt.
          <br><br>
          16.4 Die Vertragssprache ist Deutsch.
          <br><br>
          16.5 Sofern Sie Unternehmer sind oder Sie keinen allgemeinen Gerichtsstand in Deutschland oder einem anderen
          EU-Mitgliedsstaat haben oder Sie Ihren Wohnsitz nach Geltung dieser allgemeinen Nutzungsbedingungen ins Ausland
          verlegt haben oder Ihr Wohnsitz oder gewöhnlicher Aufenthaltsort bei Klageerhebung nicht bekannt ist, ist
          ausschließlicher Gerichtsstand der Sitz von Barliner Workout.
          <br><br>
          17 GUTSCHEINE & FREUNDE WERBEN
          <br><br>
          1. Gutscheincodes können nicht käuflich erworben werden, sondern werden im Rahmen von Werbekampagnen ausgeben.
          Diese sind nur im angegebenen Zeitraum gültig. Nach einmaliger Benutzung oder Ablauf der Gültigkeitsdauer
          verfällt der Gutschein. Einzelne Produkte können von der Gutscheinaktion ausgeschlossen sein.
          <br><br>
          2. Aus administrativen Gründen ist es nicht möglich, etwaige Restwerte der Gutscheine oder Guthaben zu
          erstatten. Reicht der Wert eines Gutscheins oder des Guthabens für die Bestellung nicht aus, kann der
          verbleibende Betrag mit den angebotenen Zahlungsoptionen ausgeglichen werden.
          <br><br>
          3. Gutscheine und Guthaben können nur vor Abschluss des Bestellvorgangs eingelöst werden. Eine nachträgliche
          Verrechnung ist nicht möglich.
          <br><br>
          4. Gutscheine und Guthaben können nicht auf Dritte übertragen werden. Mehrere Gutscheine können nicht
          miteinander kombiniert werden.
          <br><br>
          5. Gutscheine werden nicht erstattet, wenn Ware ganz oder teilweise retourniert wird. www.evope-sports.com
          haftet nicht für den Verlust oder die Entwendung von Aktionsgutscheinen.
          <br><br>
          Stand: 31.01.2024
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>
<style lang="scss" scoped>
#agbContainer {
  max-width: 980px;
  margin: 0 auto;
}
</style>
<script>
export default {
}
</script>
